import React, { ReactElement } from 'react'

import Layout from '../container/layout'
import { Banner, Story, Plan, Venue, Babysitters } from '../components/home/'

const IndexPage: React.FC = (): ReactElement => (
  <Layout>
    <Banner />
    <Story />
    <Plan />
    <Venue />
    <Babysitters />
  </Layout>
)

export default IndexPage
